<template>
  <v-app>
    <v-app-bar app flat height="72">
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>
          {{ drawer ? "mdi-arrow-collapse-left" : "mdi-arrow-expand-right" }}
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        {{ myName1 }}<strong>{{ myName2 }} </strong>
      </v-app-bar-title>
      <div>
        <span class="title ml-1">/ {{ title }}</span>
      </div>
      <v-spacer> </v-spacer>
      <!-- <default-tasks /> -->
      <default-search
        v-if="$store.state.auth.includes('01061') || $store.state.isadmin"
      />

      <v-btn text v-if="$store.state.isadmin" @click="modif_clear">
        <v-icon>mdi-cog-transfer</v-icon>
      </v-btn>

      <v-btn text to="/dashboard">
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <default-notifications />

      <default-account />
      <v-btn text @click="toggle_dark_mode">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <!-- <v-switch
        v-model="dark"
        class="pa-0 mt-6"
        @change="changetheme(dark)"
        dense
        flat
        inset
      >
      </v-switch> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="green"
            v-if="isEndpointAvailable"
            v-bind="attrs"
            v-on="on"
            >mdi-network</v-icon
          >
        </template>
        <span>Connection OK</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-if="!isEndpointAvailable"
            v-bind="attrs"
            v-on="on"
            >mdi-network-off</v-icon
          >
        </template>
        <span>Pas de Connection</span>
      </v-tooltip>
      <v-btn text @click="logout(1)">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      width="360"
      :mini-variant.sync="mini"
    >
      <v-list-item class="px-2 mt-2">
        <v-list-item-avatar>
          <v-img :src="require('../assets/' + img_source)"></v-img>
        </v-list-item-avatar>
        <!-- <v-list-item-title
          ><h4><strong>M</strong>PACT</h4></v-list-item-title
        > -->

        <!-- <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn> -->
      </v-list-item>

      <v-list-item></v-list-item>
      <v-list nav>
        <template v-for="item in menulist">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"> </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="child in item.children">
              <v-list-group
                no-action
                sub-group
                v-model="child.model"
                :key="child.text"
                v-if="child.children"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="child.text"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-text="child.icon"></v-icon>
                  </v-list-item-action>
                </template>

                <v-list-item
                  v-for="child2 in child.children"
                  :key="child2.text"
                  :to="
                    menus_auth.includes(child2.code) || $store.state.isadmin
                      ? child2.to
                      : '/auth'
                  "
                >
                  <v-list-item-title v-text="child2.text"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="child2.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :key="child.text"
                :to="
                  menus_auth.includes(child.code) || $store.state.isadmin
                    ? child.to
                    : '/auth'
                "
                v-model="menu_select"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.text"> </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="child.icon">
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.text"
            :to="item.to"
            v-model="menu_select"
          >
            <v-list-item-action>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless>
      <v-spacer></v-spacer>
      <span>
        Mpact v 1.9.1 PROD - Copyright © 2025

        <!-- <strong>
          {{ myname }}
        </strong> -->
      </span>
      <v-spacer></v-spacer>

      <!-- <strong class="mt-1 mx-8">{{ localTime }}</strong> -->

      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        top
        :color="snackbar_color"
      >
        {{ snackbar_text }}
      </v-snackbar>
    </v-footer>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-app>
</template>

<script>
import { onLogout, getexpiredate } from "../vue-apollo.js";
import { myLogo, myName, myName1, myName2, code, version } from "print/data.js";
import CLEAR_LOCKS from "../graphql/CLEAR_MODIF.gql";
import LOGOUT from "../graphql/LOGOUT.gql";
import UPDATE_DEMANDE from "../graphql/Demande/UPDATE_DEMANDE.gql";
import UPDATE_CONSULTATION from "../graphql/Consult/UPDATE_CONSULTATION.gql";
import UPDATE_CMD from "../graphql/Commande/UPDATE_CMD.gql";
import UPDATE_FACT from "../graphql/Facture/UPDATE_FACT.gql";
import UPDATE_MVM from "../graphql/Mouvment/UPDATE_MVM.gql";
import UPDATE_DOSSIER from "../graphql/Import/UPDATE_DOSSIER.gql";
import UPDATE_PFM from "../graphql/Proforma/UPDATE_PFM.gql";
import UPDATE_DP from "../graphql/DP/UPDATE_DP.gql";

//import GETDATE from "../graphql/GETDATE.gql";

const DARK = "darktheme";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "../components/Widgets/Account.vue"
      ),

    DefaultNotifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        "../components/Widgets/Notifications.vue"
      ),
    // DefaultTasks: () =>
    //   import(
    //     /* webpackChunkName: "default-notifications" */
    //     "../components/Widgets/Tasks.vue"
    //   ),
    DefaultSearch: () =>
      import(
        /* webpackChunkName: "default-search" */
        "../components/Widgets/Search.vue"
      ),
  },

  data: () => ({
    isEndpointAvailable: true,
    messages: 3,
    menu_select: "",
    menus_auth: [],
    title: "",
    dark: false,
    time: 10000,
    snackbar: false,
    snackbar_text: "",
    snackbar_color: "success",
    snackbar_timeout: 2000,
    drawer: null,
    mini: true,
    me: {},
    global_search: null,
    selectedItem: 0,
    localTime: " ",
    connectTime: " ",
    items: [
      {
        text: "Compagnie",
        icon: "mdi-view-dashboard",
        to: "/dashcommerce",
        menu: "0",
        model: true,
        children: [
          // {
          //   text: "Test",
          //   title: "Tableau de bord",
          //   icon: "mdi-view-dashboard",
          //   to: "/about",
          // },
          {
            text: "Tableau de bord",
            title: "Tableau de bord",
            icon: "mdi-view-dashboard",
            to: "/dashboard",
          },
          {
            text: "Mes des Documents",
            title: "Gestion des documents ",
            icon: "mdi-file-document-multiple",
            children: [
              {
                text: "Documents",
                title: "Liste des Documents",
                icon: "mdi-file-document",
                to: "/documents",
                code: "103",
              },
              {
                text: "Groupes",
                title: "Liste des Groupes",
                icon: "mdi-group",
                to: "/groups",
                code: "104",
              },
              {
                text: "Categories",
                title: "Liste des Categories",
                icon: "mdi-folder-multiple",
                to: "/categories_documents",
                code: "105",
              },
            ],
          },
          {
            text: "Achat",
            icon: "mdi-cart-arrow-down",
            code: 1,
            model: false,
            children: [
              {
                text: "Fournisseurs",
                title: "Liste des Fournisseurs",
                icon: "mdi-account-group-outline",
                to: "/fournisseurs",
                code: "029",
              },
              {
                text: "Produits",
                title: "Liste des Produits",
                icon: "mdi-alpha-p-box",
                to: "/products_02",
                code: "002",
              },

              {
                text: "Documents d'Achat",
                icon: "mdi-",
                children: [
                  {
                    text: "Demande d'Achat",
                    title: "Liste des Demandes d'Achats",
                    icon: "mdi-offer",
                    to: "/da",
                    code: "070",
                  },
                  {
                    text: "Consultations",
                    title: "Liste des Consultations d'Achats",
                    icon: "mdi-offer",
                    to: "/consult",
                    code: "071",
                  },
                  {
                    text: "Offres",
                    title: "Liste des Offres Fournisseurs",
                    icon: "mdi-offer",
                    to: "/proformas_f",
                    code: "020",
                  },
                  {
                    text: "Contrats",
                    title: "Liste des Contrats/Avenants Fournisseurs",
                    icon: "mdi-alpha-c-box",
                    to: "/contrats_f",
                    code: "076",
                  },
                  {
                    text: "Commandes ",
                    title: "Liste des Commandes Fournisseurs",
                    icon: "mdi-alpha-c-box",
                    to: "/commandes_f",
                    code: "005",
                  },
                  {
                    text: "Conventions ",
                    title: "Liste des Conventions Fournisseurs",
                    icon: "mdi-alpha-c-box",
                    to: "/convention_f",
                    code: "084",
                  },
                  {
                    text: "Dossiers Import",
                    title: "Liste des Dossiers Importation",
                    icon: "mdi-ferry",
                    to: "/imports",
                    code: "069",
                  },
                  {
                    text: "Service Fait",
                    title: "Liste des Services Fait",
                    icon: "mdi-receipt",
                    to: "/service_fait_a",
                    code: "021",
                  },
                  {
                    text: "Cautions Reçues",
                    title: "Suivi des Cautions Fournisseurs",
                    icon: "mdi-cash-100",
                    to: "/suivi_cauts_f",
                    code: "081",
                  },
                  {
                    text: "Factures",
                    title: "Liste des Factures Fournisseurs",
                    icon: "mdi-text-box",
                    to: "/facturations_f",
                    code: "014",
                  },
                  {
                    text: "Demandes de Paiements",
                    title: "Liste des Demandes de Paiements Fournisseurs",
                    icon: "mdi-text-box",
                    to: "/dps_fournis",
                    code: "083",
                  },
                  {
                    text: "Factures Avoir",
                    title: "Liste des Factures Avoir Fournisseurs",
                    icon: "mdi-text-box-minus",
                    to: "/facturations_fa",
                    code: "015",
                  },
                ],
              },
              {
                text: "Suivi",
                icon: "mdi-",
                children: [
                  {
                    text: "Suivi des Contrats",
                    title: "Suivi des Contrats Fournisseurs",
                    icon: "mdi-chart-bell-curve",
                    to: "/suivi_contrats_f",
                    code: "078",
                  },
                  {
                    text: "Suivi des Commandes",
                    title: "Suivi des Commandes Fournisseurs",
                    icon: "mdi-chart-bell-curve",
                    to: "/suivi_cmds_f",
                    code: "037",
                  },

                  {
                    text: "Suivi des Receptions",
                    title: "Suivi des Receptions",
                    icon: "mdi-cash-100",
                    to: "/suivi_rcpts",
                    code: "039",
                  },
                  {
                    text: "Suivi Factures",
                    title: "Suivi des factures fournisseurs",
                    icon: "mdi-cash-100",
                    to: "/suivi_factures_f",
                    code: "101",
                  },
                  {
                    text: "Suivi des Demandes de Paiements",
                    title: "Suivi des Demandes de Paiements Fournisseurs",
                    icon: "mdi-chart-timeline",
                    to: "/suivi_dp",
                    code: "041",
                  },

                  {
                    text: "Situation des Achats",
                    title: "Situation Dossiers Achats",
                    icon: "mdi-alpha-a-box",
                    to: "/sit_cmds_a",
                    code: "048",
                  },
                  {
                    text: "Situation des Fournisseurs",
                    title: "Situation des Fournisseurs",
                    icon: "mdi-alpha-f-box",
                    to: "/sit_fournisseurs",
                    code: "026",
                  },
                ],
              },
            ],
          },
          {
            text: "Gestion des Stocks",
            icon: "mdi-store",
            code: 3,
            model: false,
            children: [
              {
                text: "Produits",
                title: "Liste des Produits",
                icon: "mdi-alpha-p-box",
                to: "/products_34",
                code: "034",
              },
              {
                text: "Mouvements Stock",
                icon: "mdi-",
                model: false,
                children: [
                  {
                    text: "Receptions",
                    title: "Liste des Receptions Fournisseurs",
                    icon: "mdi-receipt",
                    to: "/receptions_e",
                    code: "007",
                  },
                  {
                    text: "Retours Fournisseurs",
                    title: "Liste des Retours Fournisseurs",
                    icon: "mdi-backburger",
                    to: "/retours_f",
                    code: "008",
                  },
                  {
                    text: "Bon de Sortie Magasin",
                    title: "Liste des Sorties Magasin",
                    icon: "mdi-bank-transfer-out",
                    to: "/bsmg",
                    code: "051",
                  },
                  {
                    text: "Bon de Retour Magasin",
                    title: "Liste des Retours Magasin",
                    icon: "mdi-bank-transfer-in",
                    to: "/brmg",
                    code: "052",
                  },
                  {
                    text: "Bon de Sortie Matière",
                    title: "Liste des Sorties Matière",
                    icon: "mdi-bank-transfer-out",
                    to: "/bsm",
                    code: "031",
                  },
                  {
                    text: "Bon de Réintegration",
                    title: "Liste des Réintegration Magasin",
                    icon: "mdi-bank-transfer-in",
                    to: "/brm",
                    code: "032",
                  },
                  {
                    text: "Réceptions internes",
                    title: "Liste des réceptions MP internes",
                    icon: "mdi-bank-transfer-in",
                    to: "/bof",
                    code: "044",
                  },
                  {
                    text: "Bon d'entrée magasin",
                    title: "Liste des Bons d'entrées magasin",
                    icon: "mdi-bank-transfer-in",
                    to: "/bem",
                    code: "090",
                  },
                  {
                    text: "Preparation Transfert",
                    title: "Liste des Demandes de transfert",
                    icon: "mdi-offer",
                    to: "/dtransf",
                    code: "114",
                  },
                  {
                    text: "Transferts Entre Depots",
                    title: "Liste des Transferts",
                    icon: "mdi-transfer",
                    to: "/transferts",
                    code: "011",
                  },
                  {
                    text: "Bon de réforme",
                    title: "Liste des réformes",
                    icon: "mdi-bank-transfer-out",
                    to: "/br",
                    code: "045",
                  },
                  {
                    text: "Bon de Cession Sortie",
                    title: "Liste des Cessions Sortie",
                    icon: "mdi-bank-transfer-out",
                    to: "/bcs",
                    code: "046",
                  },
                  {
                    text: "Bon de Cession Entree",
                    title: "Liste des Cessions Entree",
                    icon: "mdi-bank-transfer-in",
                    to: "/bce",
                    code: "047",
                  },
                ],
              },
              {
                text: "Mouvements Stock PF",
                icon: "mdi-",
                model: false,
                children: [
                  {
                    text: "Receptions Internes",
                    title: "Liste des Receptions PF",
                    icon: "mdi-receipt",
                    to: "/receptions_i",
                    code: "024",
                  },
                  {
                    text: "Bon d'entrée magasin",
                    title: "Liste des Bons d'entrées magasin",
                    icon: "mdi-bank-transfer-in",
                    to: "/bep",
                    code: "091",
                  },
                  {
                    text: "Expeditions",
                    title: "Liste des Expeditions Clients",
                    icon: "mdi-cart",
                    to: "/livraisons",
                    code: "009",
                  },
                  {
                    text: "Retours",
                    title: "Liste des Retours Clients",
                    icon: "mdi-backburger",
                    to: "/retours_c",
                    code: "010",
                  },
                  {
                    text: "Bon de Sortie Magasin",
                    title: "Liste des Sorties Magasin",
                    icon: "mdi-bank-transfer-out",
                    to: "/bsmp",
                    code: "093",
                  },
                  {
                    text: "Bon de Retour Magasin",
                    title: "Liste des Retours Magasin",
                    icon: "mdi-bank-transfer-in",
                    to: "/brmp",
                    code: "094",
                  },

                  {
                    text: "Transferts Entre Depots",
                    title: "Liste des Transferts",
                    icon: "mdi-transfer",
                    to: "/transferts",
                    code: "099",
                  },
                  {
                    text: "Bon de réforme",
                    title: "Liste des réformes",
                    icon: "mdi-bank-transfer-out",
                    to: "/br",
                    code: "045",
                  },
                  {
                    text: "Bon de Cession Sortie",
                    title: "Liste des Cessions Sortie",
                    icon: "mdi-bank-transfer-out",
                    to: "/bcs",
                    code: "046",
                  },
                  {
                    text: "Bon de Cession Entree",
                    title: "Liste des Cessions Entree",
                    icon: "mdi-bank-transfer-in",
                    to: "/bce",
                    code: "047",
                  },
                ],
              },
              {
                text: "Stocks ",
                title: "Etat des stocks",
                icon: "mdi-store",
                to: "/stock_gsm",
                code: "057",
              },
              {
                text: "Consommations",
                title: "Suivi des Consommations",
                icon: "mdi-chart-bell-curve",
                to: "/consom",
                code: "089",
              },
              {
                text: "Produits Perissable MP",
                title: "Liste des produits perissable",
                icon: "mdi-select-off",
                to: "/items_expire_mp",
                code: "097",
              },
              {
                text: "Produits Perissable PF",
                title: "Liste des produits perissable",
                icon: "mdi-select-off",
                to: "/items_expire_pf",
                code: "098",
              },
              {
                text: "Stocks PF",
                title: "Etat des stocks",
                icon: "mdi-store",
                to: "/stock_v",
                code: "053",
              },
              {
                text: "Chargements",
                title: "Liste des Chargements",
                icon: "mdi-truck-delivery-outline",
                to: "/colis",
                code: "050",
              },

              {
                text: "Inventaire",
                title: "Inventaire des Produits",
                icon: "mdi-transfer",
                to: "/inventaires",
                code: "028",
              },
            ],
          },

          {
            text: "Production",
            icon: "mdi-factory",
            code: 4,
            model: false,
            hiden: false,
            children: [
              {
                text: "Produits",
                title: "Liste des Produits",
                icon: "mdi-alpha-p-box",
                to: "/products_04",
                code: "004",
              },
              {
                text: "Ordres de Fabrication ",
                title: "Liste des Ordres de Fabrication",
                icon: "mdi-alpha-c-box",
                to: "/orders_p",
                code: "035",
              },

              {
                text: "Bons de Travail ",
                title: "Liste des Bons de Travail ",
                icon: "mdi-alpha-b-box",
                to: "/bts",
                code: "082",
              },
              {
                text: "Fiches de Fabrication ",
                title: "Liste des Fiches de Fabrication",
                icon: "mdi-alpha-f-box",
                to: "/fiches",
                code: "023",
              },
              {
                text: "Fiches de Transformation ",
                title: "Liste des Fiches de Transformation",
                icon: "mdi-alpha-t-box",
                to: "/transf",
                code: "023",
              },
              {
                text: "Fiches Produits Finis ",
                title: "Liste des Fiches de Produits Finis",
                icon: "mdi-alpha-p-box",
                to: "/produitfinis",
                code: "115",
              },
              {
                text: "Encours Production",
                title: "Liste des encours de la production",
                icon: "mdi-alpha-e-box",
                to: "/encours_p",
                code: "095",
              },
              {
                text: "Intrants Production",
                title: "Liste des Intrants en circulation",
                icon: "mdi-alpha-i-box",
                to: "/encours_i",
                code: "096",
              },
              {
                text: "Machines de Production",
                title: "Machine de Production",
                icon: "mdi-alpha-m-box",
                to: "/machines",
                code: "066",
              },

              {
                text: "Types d'arrets",
                title: "Types d'arrets de Production",
                icon: "mdi-alpha-a-box",
                to: "/arrets",
                code: "066",
              },
            ],
          },
          {
            text: "Controle Qualite",
            icon: "mdi-check-decagram",
            code: 5,
            model: false,
            hiden: false,
            children: [
              {
                text: "Reception Non Conforme",
                title: "Liste des Receptions Non Conformes",
                icon: "mdi-receipt",
                to: "/receptions_nc",
                code: "062",
              },
              {
                text: "Stock Non Conforme",
                title: "Stock des Produits Non Conformes",
                icon: "mdi-receipt",
                to: "/stock_nc",
                code: "063",
              },
            ],
          },
          {
            text: "Consommation Encre",
            title: "Suivi PrePress",
            icon: "mdi-chart-bell-curve",
            to: "/suivi_demandes_c",
            code: "111",
          },
          {
            text: "Ventes ",
            icon: "mdi-cart-arrow-right",
            code: 2,
            model: false,
            children: [
              {
                text: "Clients",
                title: "Liste des Clients",
                icon: "mdi-account-group",
                to: "/clients",
                code: "030",
              },
              {
                text: "Produits",
                title: "Liste des Produits",
                icon: "mdi-alpha-p-box",
                to: "/products_03",
                code: "003",
              },
              {
                text: "Documents de vente",
                icon: "mdi-",
                model: false,
                children: [
                  {
                    text: "Demande d'Offre",
                    title: "Liste des Demandes d'Offres",
                    icon: "mdi-offer",
                    to: "/offre",
                    code: "086",
                  },
                  {
                    text: "Proformas",
                    title: "Liste des Proformas Client",
                    icon: "mdi-offer",
                    to: "/proformas_c",
                    code: "022",
                  },
                  {
                    text: "Contrats",
                    title: "Liste des Contrats/Avenants Clients",
                    icon: "mdi-alpha-c-box",
                    to: "/contrats_c",
                    code: "077",
                  },
                  {
                    text: "Commandes Clients",
                    title: "Liste des Commandes Clients",
                    icon: "mdi-alpha-c-box",
                    to: "/commandes_c",
                    code: "006",
                  },
                  {
                    text: "Service Fait",
                    title: "Liste des Services Fait",
                    icon: "mdi-receipt",
                    to: "/service_fait_v",
                    code: "100",
                  },
                  {
                    text: "Cautions Emises",
                    title: "Suivi des Cautions Clients",
                    icon: "mdi-cash-100",
                    to: "/suivi_cauts_c",
                    code: "080",
                  },
                  {
                    text: "Commandes Internes",
                    title: "Liste des Commandes Internes",
                    icon: "mdi-alpha-i-box",
                    to: "/orders_v",
                    code: "036",
                  },
                  {
                    text: "Facturations",
                    title: "Liste des Factures Clients",
                    icon: "mdi-text-box",
                    to: "/facturations_c",
                    code: "012",
                  },
                  {
                    text: "Factures Avoir",
                    title: "Liste des Factures Avoir Clients",
                    icon: "mdi-text-box-minus",
                    to: "/facturations_ca",
                    code: "013",
                  },
                  {
                    text: "Demandes de Paiements",
                    title: "Liste des Demandes de Paiements Clients",
                    icon: "mdi-text-box",
                    to: "/dps_clients",
                    code: "107",
                  },
                ],
              },
              {
                text: "Suivi",
                icon: "mdi-",
                model: false,
                children: [
                  {
                    text: "Suivi des Contrats",
                    title: "Suivi des Contrats Clients",
                    icon: "mdi-chart-bell-curve",
                    to: "/suivi_contrats_c",
                    code: "079",
                  },
                  {
                    text: "Suivi des Commandes",
                    title: "Suivi des Commandes Clients",
                    icon: "mdi-chart-bell-curve",
                    to: "/suivi_cmds_c",
                    code: "038",
                  },

                  {
                    text: "Suivi des Expeditions",
                    title: "Suivi des Expeditions Clients",
                    icon: "mdi-cash-100",
                    to: "/suivi_bls",
                    code: "042",
                  },

                  {
                    text: "Suivi des Factures",
                    title: "Suivi des Factures Clients",
                    icon: "mdi-chart-timeline",
                    to: "/suivi_factures_c",
                    code: "040",
                  },

                  {
                    text: "Situation des Ventes",
                    title: "Situation Dossiers Ventes",
                    icon: "mdi-alpha-v-box",
                    to: "/sit_cmds_v",
                    code: "049",
                  },

                  {
                    text: "Situation des Clients",
                    title: "Situations des Clients",
                    icon: "mdi-alpha-c-box",
                    to: "/sit_clients",
                    code: "025",
                  },
                ],
              },
            ],
          },
          {
            text: "Compta/Trésorerie ",
            icon: "mdi-cash",
            code: 6,
            model: false,
            children: [
              {
                text: "Tiers",
                title: "Liste des Tiers",
                icon: "mdi-alpha-t-box",
                to: "/tiers",
                code: "001",
              },
              {
                text: "Encaissements",
                title: "Liste des Encaissements",
                icon: "mdi-account-cash",
                to: "/encaissements",
                code: "017",
              },
              {
                text: "Paiements ",
                title: "Liste des Paiements",
                icon: "mdi-account-cash",
                to: "/paiements",
                code: "016",
              },
              {
                text: "Journaux && Operations",
                title: "Journaux && Operations",
                icon: "mdi-alpha-j-box",
                to: "/journaux",
                code: "116",
              },
              {
                text: "Demandes de Paiements",
                title: "Liste des Demandes de Paiements",
                icon: "mdi-text-box",
                to: "/dps_compta",
                code: "108",
              },
            ],
          },
          {
            text: "Config. Produit",
            icon: "mdi-wrench-cog-outline",

            model: false,
            children: [
              {
                text: "Modeles de Produits",
                title: "Modeles de Produits",
                icon: "mdi-alpha-m-box",
                to: "/modeles",
                code: "018",
              },
              {
                text: "Attributs",
                title: "Attributs de Modeles",
                icon: "mdi-format-text-variant",
                to: "/attributs",
                code: "019",
              },

              {
                text: "Process de Production",
                title: "Process de Production",
                icon: "mdi-alpha-g-box",
                to: "/gammes",
                code: "066",
              },
              {
                text: "Gammes de Production",
                title: "Gammes de Production",
                icon: "mdi-alpha-p-box",
                to: "/process",
                code: "066",
              },
            ],
          },
          {
            text: "Administration ",
            icon: "mdi-office-building-cog",

            model: false,
            children: [
              {
                text: "Tables de Base",
                title: "Données de Base",
                icon: "mdi-file-table-box-multiple",
                to: "/dictionnaire",
                code: "074",
              },
              {
                text: "Events",
                title: "Gestion des Events",
                icon: "mdi-calendar-text",
                to: "/events",
                code: "0A",
              },
              {
                text: "Depots",
                title: "Liste des Depots",
                icon: "mdi-store",
                to: "/depotslist",
                code: "0A",
              },
              {
                text: "Categories",
                title: "Categories",
                icon: "mdi-book-variant",
                to: "/categories",
                code: "075",
              },
              {
                text: "Structures",
                title: "Structures",
                icon: "mdi-book-variant",
                to: "/structures",
                code: "075",
              },
              {
                text: "Employés",
                title: "Liste des Employés",
                icon: "mdi-account-multiple",
                to: "/agents",
                code: "066",
              },

              {
                text: "Profils & Utilisateurs",
                title: "Profils & Utilisateurs",
                icon: "mdi-account-circle",
                to: "/users",
                code: "0A",
              },
            ],
          },
        ],
      },
    ],
    menulist: [],
    menu: "0",
  }),

  async mounted() {
    //this.checkEndpointStatus();
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
    if (code == 1) {
      this.items[0].children[6].children.push({
        text: "Simulateur",
        title: "Simulateur de calcul",
        icon: "mdi-account-multiple",
        to: "/simulateur",
        code: "106",
      });
      this.items[0].children[4].children.push({
        text: "Flash de Production",
        title: "Flash quotidien de production",
        icon: "mdi-view-dashboard",
        to: "/flash",
        code: "110",
      });
    }
  },

  async created() {
    await this.initialize();
    this.$store.state.auth.forEach((element) => {
      if (element.substring(1, 2) == 1) {
        const i = this.menus_auth.findIndex(
          (elm) => elm == element.substring(2)
        );
        if (i == -1) this.menus_auth.push(element.substring(2));
      }
    });
    this.updatetitle();
    this.showLocaleTime();
    this.connectTime = new Date().toLocaleString();

    if (this.$store.state.me.profile.modules) {
      let m = this.$store.state.me.profile.modules.split(",");

      let i = this.items[0].children.findIndex((elm) => elm.code == m[0]);
      if (i >= 0) this.items[0].children[i].model = true;
    }
    if (this.version == 1) {
      this.items[0].children[3].hiden = true;
      this.items[0].children[4].hiden = true;
      this.items[0].children[7].hiden = true;
      this.items[0].children[2].children.splice(1, 1);
      this.items[0].children[2].children.splice(1, 1);
      this.items[0].children[5].children.splice(5, 1);
      this.items[0].children[5].children.splice(5, 1);
    }
    if (this.version == 2) {
      // this.items[0].children[3].children.splice(4, 1);
      // this.items[0].children[3].children.splice(4, 1);
      // this.items[0].children[3].children.splice(4, 1);
    }
    this.menulist = this.childrensItem();
  },
  watch: {
    $route() {
      this.updatetitle();
    },
    "$store.state.idleVue.isIdle": {
      handler() {
        if (this.$store.state.idleVue.isIdle) {
          this.showDisconnectTime();
          this.snackbar_timeout = 10000;
          this.snackbar_color = "warning";
        } else {
          this.snackbar = false;
          this.time = 10000;
          this.snackbar_timeout = 2000;
        }
      },
      deep: true,
    },
  },

  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    code_client() {
      return code;
    },
    img_source() {
      return code == 1 ? "ALFAPIPE2.jpg" : "Mpact.gif";
    },
    version() {
      return version;
    },
    mylogo() {
      return myLogo;
    },
    myname() {
      return myName;
    },
    myName1() {
      return myName1;
    },
    myName2() {
      return myName2;
    },
  },
  // apollo: {
  //   // Query with parameters
  //   getdate: {
  //     query: GETDATE,
  //     // Additional options here
  //     fetchPolicy: "network-only",
  //     pollInterval: 2000,
  //     result({ loading, networkStatus }) {
  //       let oldstatut = this.isEndpointAvailable;
  //       if (!loading) this.isEndpointAvailable = networkStatus == 7;
  //       if (oldstatut && !this.isEndpointAvailable) {
  //         this.snackbar_text = "Connection avec le serveur intrompue!";
  //         this.snackbar_color = "error";
  //         this.snackbar = true;
  //       }
  //       if (!oldstatut && this.isEndpointAvailable) {
  //         this.snackbar_text = "Connection restaurée";
  //         this.snackbar_color = "success";
  //         this.snackbar = true;
  //       }
  //     },
  //   },
  // },
  methods: {
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .watch.then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch(() => {});
      return r;
    },
    // checkEndpointStatus() {
    //   this.$apollo.queries.getdate;
    // },

    async modif_clear() {
      await this.maj(CLEAR_LOCKS);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
          this.snackbar = true;
          this.snackbar_text = "Document cleared";
          this.snackbar_color = "success";
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    redirect() {
      this.$router.push("/");
      clearInterval();
    },
    showDisconnectTime: function () {
      this.time = 10000;
      this.snackbar_text =
        "10 minutes d'innactivité, deconnexion dans " +
        this.time / 1000 +
        " secondes";
      this.snackbar = true;
      let timerId = setInterval(() => {
        this.time -= 1000;
        this.snackbar_text =
          "10 minutes d'innactivité, deconnexion dans " +
          this.time / 1000 +
          " secondes";
        if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
        if (this.time < 1) {
          clearInterval(timerId);
          // Your logout function should be over here
          this.logout(2);
        }
      }, 1000);
    },
    showLocaleTime: function () {
      var time = this;
      setInterval(function () {
        time.localTime = new Date().toLocaleTimeString();
        // soit router soit renouvler
        if (time.$route.path != "/") {
          if (getexpiredate() < new Date()) {
            clearInterval();
            time.$router.push("/");
          }
        }
      }, 5000);
    },

    updatetitle() {
      let i = this.items[0].children.findIndex(
        (elm) => elm.to == this.$route.path
      );
      if (i >= 0) this.title = this.items[0].children[i].title;
      else {
        let ok = false;
        for (let index = 0; index < this.items[0].children.length; index++) {
          let element = this.items[0].children[index];
          if (ok) break;
          if (element.children) {
            for (let j = 0; j < element.children.length; j++) {
              const element2 = element.children[j];
              if (ok) break;
              if (element2.children) {
                i = element2.children.findIndex(
                  (elm) => elm.to == this.$route.path
                );
                if (i >= 0) {
                  this.title = element2.children[i].title;
                  ok = true;
                  break;
                }
              } else {
                if (element2.to == this.$route.path) {
                  this.title = element2.title;
                  ok = true;
                  break;
                }
              }
            }
          }
        }
      }
    },

    changetheme(value) {
      if (value) localStorage.setItem(DARK, "1");
      else localStorage.setItem(DARK, "0");
      this.$vuetify.theme.dark = value;
    },
    childrensItem() {
      let list = [];
      let l;
      let list2 = this.items[0].children.filter((elm) => elm.hiden != true);
      if (this.$store.state.isadmin) {
        list = list2;
      } else {
        for (let index = 0; index < list2.length; index++) {
          if (list2[index].children) {
            const element = list2[index].children.filter(
              (elm) => this.menus_auth.includes(elm.code) || elm.children
            );
            if (element.length) {
              l = Object.assign({}, list2[index]);
              l.children = [];
              element.forEach((elm5) => {
                //traitement sub group
                if (elm5.children) {
                  const elm2 = elm5.children.filter((elm) =>
                    this.menus_auth.includes(elm.code)
                  );
                  if (elm2.length) {
                    let l2 = Object.assign({}, elm5);
                    l2.children = [];
                    elm2.forEach((elm4) => {
                      l2.children.push(elm4);
                    });
                    l.children.push(l2);
                  }
                } else l.children.push(elm5);
              });
              if (l.children.length > 0) list.push(l);
            }
          } else if (
            this.menus_auth.includes(list2[index].code) ||
            !list2[index].code
          )
            list.push(list2[index]);
        }
      }

      return list;
    },
    initialize() {
      if (localStorage.getItem(DARK) != null) {
        const dark1 = localStorage.getItem(DARK);
        if (dark1 == "1") this.dark = true;
        else this.dark = false;
      }
      this.$vuetify.theme.dark = this.dark;
      this.me = this.$store.state.me;
    },

    set_search() {
      this.$store.dispatch("set_search_text", this.global_search);
    },
    async update_modif() {
      if (this.$store.state.document.id > 0) {
        if (this.$store.state.document.table == "commandes") {
          let v = {
            cmd: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_CMD, v);
        }
        if (this.$store.state.document.table == "proformas") {
          let v = {
            input: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_PFM, v);
        }
        if (this.$store.state.document.table == "mouvments") {
          let v = {
            input: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_MVM, v);
        }
        if (this.$store.state.document.table == "factures") {
          let v = {
            input: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_FACT, v);
        }
        if (this.$store.state.document.table == "demandes") {
          let v = {
            demande: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_DEMANDE, v);
        }
        if (this.$store.state.document.table == "consultations") {
          let v = {
            consultation: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_CONSULTATION, v);
        }
        if (this.$store.state.document.table == "dps") {
          let v = {
            dp: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_DP, v);
        }
        if (this.$store.state.document.table == "dossiers_import") {
          let v = {
            input: {
              id: this.$store.state.document.id,
              modif_by: null,
              modif_date: null,
            },
          };
          await this.maj(UPDATE_DOSSIER, v);
        }
      }
      this.$store.dispatch("Editing", false);
    },
    async logout(c) {
      let ok = true;
      if (this.$store.state.isEditing && c == 1) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Document Ouvert !! Etês-vous sûr ?",
            { color: "orange darken-3" }
          )
        ) {
          ok = true;
          await this.update_modif();
        }
      }
      if (this.$store.state.isEditing && c == 2) {
        await this.update_modif();
      }
      if (ok) {
        await this.maj(LOGOUT);
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push("/");
      }
    },
  },
};
</script>
<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
